import React, { useEffect, useState } from "react";
import Header from "../../Component/common/Header";
import { months } from "../../Container/Data/General";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  FaArrowRight,
  FaMinus,
  FaPlaneArrival,
  FaPlaneDeparture,
  FaPlus,
  FaRegUser,
} from "react-icons/fa";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { HiOutlineSwitchVertical } from "react-icons/hi";
import { IoArrowBackOutline } from "react-icons/io5";
import axios from "axios";
import { format } from "date-fns";
import { useParams } from "react-router";
// import { airRoutes } from "../../Container/Data/Airlines";
import { Link } from "react-router-dom";

function Flight() {
  const { destiny } = useParams();
  const query = useQuery();
  let origin = query.get("origin");
  let date = query.get("date");
  let destination = query.get("destiny");
  const [loading, setLoading] = useState(true);
  const [airRoutes, setAirRoutes] = useState([]);
  const [airPortSearch, setAirPortSearch] = useState([]);
  const [showFliter, setShowFilter] = useState(false);
  const [departureSelected, setDepatureSelected] = useState({
    slug: origin,
    subtitle: "",
    title: "",
  });
  const [ariveSelected, setAriveSelected] = useState({
    slug: query.get("destiny"),
    subtitle: "",
    title: "",
  });
  const [handleShowDeparture, setHandleShowDepature] = useState(false);
  const [showBanner, setShowBanner] = useState(true);
  const [flightError, setFlightError] = useState({
    error: false,
    errormessage: "",
  });
  // const [airRoute, setAirRoute] = useState([]);
  const [error, setError] = useState({
    depature: false,
    arive: false,
    date: false,
    passanger: false,
  });
  const [passanger, setPassanger] = useState({
    adult: parseInt(query.get("passanger")),
    child: 0,
    infants: 0,
  });
  const [showPassager, setShowPassager] = useState(false);
  const [handleShowArive, setHandleShowArive] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    new URLSearchParams(window.location.search).get("date")
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [showHeader, setShowHeader] = React.useState(false);

  const _handleredirect = () => {
    if (!departureSelected) {
      return setError({
        ...error,
        depature: true,
      });
    }
    if (!ariveSelected) {
      return setError({
        ...error,
        arive: true,
      });
    }
    if (!selectedDate) {
      return setError({
        ...error,
        date: true,
      });
    }
    setError({
      ...error,
      passanger: true,
    });
    if (passanger.adult || passanger.child || passanger.infants) {
      let month = months.findIndex((state) =>
        state.toLowerCase().startsWith(selectedDate.split("-")[1].toLowerCase())
      );
      console.log(month);

      let link = `/in/flight/search/?origin=${
        departureSelected.slug
      }&date=${selectedDate}&destiny=${ariveSelected.slug}&passanger=${
        passanger.adult + passanger.child + passanger.infants
      }&currency=usd&language=en&locale=en&marker=496396.Zzd4c7f209a7df4067800d1ae-496396&with_request=true`;
      window.location.replace(link);
    }
  };

  const _handleSearch = async (e) => {
    try {
      const request = await axios.get(
        `https://suggest.travelpayouts.com/search?service=aviasales&term=${e.target.value}&locale=en`
      );
      setAirPortSearch(request.data);
    } catch (err) {
      setAirPortSearch([]);
    }
  };

  function useQuery() {
    return new URLSearchParams(window.location.search);
  }

  const _handleFlightSearch = async () => {
    let passanger = query.get("passanger");
    setLoading(true);
    try {
      const response = await axios.post(
        "http://localhost:8080/api/v1/flights",
        {
          origin: origin,
          date: date,
          destination: destination, // Ensure consistency in naming
          passanger: passanger,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data);
      setLoading(false);
      setFlightError(false);
      return setAirRoutes(response.data);
    } catch (error) {
      console.error("Error during flight search:", error);
      setLoading(false);
      return setFlightError(true);
    }
  };

  useEffect(() => {
    _handleFlightSearch();

    // Optional: Cleanup logic if needed
    return () => {
      console.log("Cleanup logic can go here if necessary");
    };
  }, []);

  return (
    <div className=" bg-black max-[1250px]:px-10 py-4 relative min-h-screen max-[950px]:h-auto w-full max-md:h-auto  max-md:pb-0 max-md:-mb-10 max-sm:px-0">
      <div className="px-10 max-md:px-6 ">
        <Header />
      </div>
      <div className="w-full sticky top-0 bg-black z-50 px-6 border-y border-y-white/10 py-2  mt-4  left-0  max-md:bottom-0 max-lg:relative">
        <div className="flex items-center w-full justify-between max-md:flex-col max-[1250px]:flex-wrap max-sm:12">
          <div className="w-1/5 py-3 border-l-white/15 border-r max-md:border-r-none  max-md:border-l max-md:border-l-white/15 px-4 max-md:px-16 max-md:w-full max-md:mb-3 relative max-[1250px]:w-1/2">
            <span className="text-base font-semibold text-white">From</span>
            <div
              onClick={() => {
                setHandleShowDepature(!handleShowDeparture);
                setHandleShowArive(false);
                setShowPassager(false);
              }}
              className={`${
                error.depature ? "border-2" : "border-none"
              } w-full border-red-600 select-none  rounded-md mt-1.5 `}
            >
              <div className="flex items-center">
                <span className="mr-5">
                  {departureSelected ? (
                    <span>
                      <div className="text-5xl font-bold text-white">
                        {departureSelected?.slug}
                      </div>
                      <div className="text-sm text-white/70">
                        {departureSelected?.title}
                      </div>
                    </span>
                  ) : (
                    "select flight"
                  )}
                </span>{" "}
              </div>
            </div>
            {handleShowDeparture ? (
              <div className="absolute top-20 max-md:fixed max-md:top-0 max-md:left-0 max-md:bottom-0 max-md:right-0 max-md:bg-black max-md:max-h-screen max-md:z-50 w-full bg-white max-h-72 overflow-y-scroll z-10">
                <div className="sticky top-0 h-12 flex items-center justify-center max-md:border-b max-md:border-b-white/15 max-md:bg-black">
                  <div
                    className="text-red-300 max-md:block hidden px-4"
                    onClick={() => {
                      setError({
                        ...error,
                        depature: false,
                      });
                      setAirPortSearch([]);
                      setHandleShowDepature(false);
                    }}
                  >
                    <IoArrowBackOutline />
                  </div>
                  <input
                    onChange={_handleSearch}
                    type="text"
                    placeholder="search airport..."
                    className="w-full h-full border border-black/10 px-4 max-md:bg-black max-md:text-white max-md:outline-none text-base"
                  />
                </div>
                {airPortSearch.map((airport, index) => (
                  <div
                    onClick={() => {
                      setDepatureSelected(airport);
                      setError({
                        ...error,
                        depature: false,
                      });
                      setAirPortSearch([]);
                      setHandleShowDepature(false);
                    }}
                    className="py-3 px-6 cursor-pointer max-md:text-white/70 flex items-center"
                  >
                    <div className="py-2 px-3 text-xs mr-2 hidden max-md:block text-black rounded-md bg-[#fc6c3a]">
                      {airport.slug}
                    </div>
                    {airport.title}
                  </div>
                ))}
              </div>
            ) : null}
            <div className="h-12 w-12 rounded-full border  absolute top-8 -right-6 max-md:-left-5 bg-black border-white/40 flex items-center justify-center">
              <HiOutlineSwitchVertical color="#fc6c3a" />
            </div>
          </div>
          <div className="w-1/5 py-3 border-r-white/15 max-md:border-r-none max-md:px-16 max-md:border-l max-md:border-l-white/15 border-r px-12 \ max-md:w-full max-md:mb-3 relative max-[1250px]:w-1/2">
            <span className="text-base font-semibold text-white">To</span>
            <div
              onClick={() => {
                setHandleShowDepature(false);
                setHandleShowArive(!handleShowArive);
                setShowPassager(false);
              }}
              className={`${
                error.arive ? "border-2" : "border-none"
              } w-full border-red-600 select-none  rounded-md mt-1.5 `}
            >
              <div className="flex items-center">
                <span className="mr-5">
                  {ariveSelected ? (
                    <span>
                      <div className="text-5xl font-bold text-white">
                        {ariveSelected?.slug}
                      </div>
                      <div className="text-sm text-white/70">
                        {ariveSelected?.title}
                      </div>
                    </span>
                  ) : (
                    "select flight"
                  )}
                </span>{" "}
              </div>
            </div>
            {handleShowArive ? (
              <div className="absolute top-20 max-md:fixed max-md:top-0 max-md:left-0 max-md:bottom-0 max-md:right-0 max-md:bg-black max-md:max-h-screen max-md:z-50 w-full bg-white max-h-72 overflow-y-scroll z-10">
                <div className="sticky top-0 h-12 flex items-center justify-center max-md:border-b max-md:border-b-white/15 max-md:bg-black">
                  <div
                    className="text-red-300 max-md:block hidden px-4"
                    onClick={() => {
                      setAirPortSearch([]);
                      setError({
                        ...error,
                        arive: false,
                      });
                      setHandleShowArive(false);
                    }}
                  >
                    <IoArrowBackOutline />
                  </div>
                  <input
                    onChange={_handleSearch}
                    type="text"
                    placeholder="search airport..."
                    className="w-full h-full border border-black/10 px-4 max-md:bg-black max-md:text-white max-md:outline-none text-base"
                  />
                </div>
                {airPortSearch.map((airport, index) => (
                  <div
                    onClick={() => {
                      setAriveSelected(airport);
                      setAirPortSearch([]);
                      setError({
                        ...error,
                        arive: false,
                      });
                      setHandleShowArive(false);
                    }}
                    className="py-3 px-6 cursor-pointer max-md:text-white/70 flex items-center"
                  >
                    <div className="py-2 px-3 text-xs mr-2 hidden max-md:block text-black rounded-md bg-[#fc6c3a]">
                      {airport.slug}
                    </div>
                    {airport.title}
                  </div>
                ))}
              </div>
            ) : null}
          </div>
          <div
            onClick={() => document.getElementById("date-picker").click()}
            className="w-1/5 py-3 border-r-white/15 max-md:px-16 max-md:border-l max-md:border-l-white/15 border-r px-12  \ max-md:w-full max-md:mb-3 relative max-[1250px]:w-1/2"
          >
            <span className="text-base text-white font-semibold">
              Departure
            </span>
            <div
              className={` ${
                error.date ? "border-2" : "border-none"
              } w-full  border-red-600 relative rounded-md mt-1.5 flex items-center justify-between `}
            >
              <span>
                <div className="text-5xl font-bold text-white">
                  {selectedDate.split("-")[2]}
                </div>
                <div className="text-sm text-white/70">
                  {" "}
                  {months[parseInt(selectedDate.split("-")[1] - 1)]}{" "}
                  {selectedDate.split("-")[0]}
                </div>
              </span>
              <DatePicker
                id="date-picker"
                className="hidden"
                dateFormat="dd-MM-yyyy"
                onChange={(date) => {
                  if (date) {
                    console.log(date.getMonth()); // Logs the month (0-based)
                    console.log(
                      `${date.getFullYear()}-${
                        date.getMonth() + 1 < 10
                          ? `0${date.getMonth() + 1}`
                          : date.getMonth() + 1
                      }${date.getDate()}`
                    );
                    setSelectedDate(
                      `${date.getFullYear()}-${
                        date.getMonth() + 1 < 10
                          ? `0${date.getMonth() + 1}`
                          : date.getMonth() + 1
                      }-${date.getDate()}`
                    );
                  }
                }}
                popperPlacement="bottom" // Controls where the calendar pops up
              />
            </div>
          </div>
          <div className="w-1/5 py-3 border-r-white/15 max-md:px-16 max-md:border-l max-md:border-l-white/15 border-r px-12 \ max-md:w-full max-md:mb-3 relative max-[1250px]:w-1/2">
            <span className="text-base text-white font-semibold">
              Passanger
            </span>
            <div
              className={`${
                error.arive ? "border-2" : "border-none"
              } py-1 w-full  border-red-600 rounded-md mt-1.5  relative`}
            >
              <div
                className="w-full h-full"
                onClick={() => {
                  setHandleShowDepature(false);
                  setHandleShowArive(false);
                  setShowPassager(!showPassager);
                }}
              >
                <h1 className="text-5xl font-bold text-white">
                  {parseInt(passanger.child) +
                    parseInt(passanger.adult) +
                    parseInt(passanger.infants)}
                </h1>
                <div className="flex items-center text-white/40">
                  <FaRegUser color="rgba(0,0,0,0.8)" />
                  <span className=" mr-5">
                    {passanger.adult} adult , {passanger.child} children,{" "}
                    {passanger.infants} Infants
                  </span>
                </div>
                <IoIosArrowDropdownCircle color="rgba(0,0,0,0.8)" />
              </div>
              {showPassager ? (
                <div className="absolute top-12 left-0 w-full bg-white  shadow-md max-h-72 overflow-y-scroll px-4">
                  <div className="flex items-center justify-between py-2">
                    <div>
                      Adult
                      <div className="text-xs text-black/80">(12+ years)</div>
                    </div>
                    <div className="flex items-center">
                      <div
                        className="mr-2"
                        onClick={() => {
                          if (passanger.adult > 0) {
                            setPassanger((prevState) => ({
                              ...prevState,
                              adult: prevState.adult - 1,
                            }));
                          }
                        }}
                      >
                        <FaMinus />
                      </div>
                      <div className="p-2 mx-2  rounded-full ">
                        {passanger.adult}
                      </div>
                      <div
                        className="p-2 ml-2 border border-black rounded-full "
                        onClick={() => {
                          setPassanger((prevState) => ({
                            ...prevState,
                            adult: prevState.adult + 1,
                          }));
                        }}
                      >
                        <FaPlus />
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-between py-2">
                    <div>
                      Children
                      <div className="text-xs text-black/80">(2-12 years)</div>
                    </div>
                    <div className="flex items-center">
                      <div
                        className="mr-2"
                        onClick={() => {
                          if (passanger.child > 0) {
                            setPassanger((prevState) => ({
                              ...prevState,
                              child: prevState.child - 1,
                            }));
                          }
                        }}
                      >
                        <FaMinus />
                      </div>
                      <div className="p-2 mx-2  rounded-full ">
                        {passanger.child}
                      </div>
                      <div
                        className="p-2 ml-2 border border-black rounded-full "
                        onClick={() => {
                          setPassanger((prevState) => ({
                            ...prevState,
                            child: prevState.child + 1,
                          }));
                        }}
                      >
                        <FaPlus />
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-between py-2">
                    <div>
                      Infants
                      <div className="text-xs text-black/80">(below 2 yrs)</div>
                    </div>
                    <div className="flex items-center">
                      <div
                        className="mr-2"
                        onClick={() => {
                          if (passanger.infants > 0) {
                            setPassanger((prevState) => ({
                              ...prevState,
                              infants: prevState.infants - 1,
                            }));
                          }
                        }}
                      >
                        <FaMinus />
                      </div>
                      <div className="p-2 mx-2  rounded-full ">
                        {passanger.infants}
                      </div>
                      <div
                        className="p-2 ml-2 border border-black rounded-full "
                        onClick={() => {
                          setPassanger((prevState) => ({
                            ...prevState,
                            infants: prevState.infants + 1,
                          }));
                        }}
                      >
                        <FaPlus />
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="w-1/5 py-3 border-r-white/15 max-md:border-l max-md:border-l-white/15 border-r px-12  \ max-md:w-full max-md:mb-3 relative max-[1250px]:w-1/2">
            <div
              onClick={_handleredirect}
              className="w-full bg-[#fc6c3a] h-16 text-center text-black flex items-center justify-center text-xl rounded-lg font-semibold"
            >
              Search
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-start w-full px-16 mt-7 relative max-md:flex-col max-lg:px-2">
        <div className="w-2/6 py-5 px-6 border border-white/20 sticky top-32 max-md:top-0 max-md:w-full max-md:hidden">
          <div className="text-white/70">Filter by</div>
          <div className="mt-4 mx-2">
            <h1 className="text-white">Stops</h1>
            <div className="ml-3">
              <div className="flex items-center justify-start mt-3">
                <input
                  type="checkbox"
                  className="w-4 h-4 border border-white/30 focus:ring-0 focus:outline-none"
                />
                <span className="ml-2 text-white/70">1 stop</span>
              </div>
              <div className="flex items-center justify-start mt-2">
                <input
                  type="checkbox"
                  className="w-4 h-4 border border-white/30 focus:ring-0 focus:outline-none"
                />
                <span className="ml-2 text-white/70">Non stop</span>
              </div>
            </div>
          </div>
          <div class="py-8 text-white">
            <div class="flex flex-between flex-middle c-neutral-900 c-pointer hover:c-secondary-500 br-4 px-1 py-1 nmx-1 nmy-1 p-relative z-20">
              <p class="to-ellipsis o-hidden ws-nowrap fs-body fw-400 c-neutral-900 fw-600">
                Departure time{" "}
              </p>
            </div>
            <div className="ml-3">
              <div className="flex items-center justify-start mt-3">
                <input
                  type="checkbox"
                  className="w-4 h-4 border border-white/30 focus:ring-0 focus:outline-none"
                />
                <span className="ml-2 text-white/70">
                  Early morning{" "}
                  <span className="text-sm text-white/50">
                    (Midnight - 8 am)
                  </span>
                </span>
              </div>
              <div className="flex items-center justify-start mt-2">
                <input
                  type="checkbox"
                  className="w-4 h-4 border border-white/30 focus:ring-0 focus:outline-none"
                />
                <span className="ml-2 text-white/70">
                  Morning (<span>8 am - Noon</span>)
                </span>
              </div>
              <div className="flex items-center justify-start mt-2">
                <input
                  type="checkbox"
                  className="w-4 h-4 border border-white/30 focus:ring-0 focus:outline-none"
                />
                <span className="ml-2 text-white/70">
                  Afternoon (<span>Noon - 4 pm</span>)
                </span>
              </div>
              <div className="flex items-center justify-start mt-2">
                <input
                  type="checkbox"
                  className="w-4 h-4 border border-white/30 focus:ring-0 focus:outline-none"
                />
                <span className="ml-2 text-white/70">
                  Night (<span>8 pm - Midnight</span>)
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="w-4/6  ml-6  max-md:w-full max-md:ml-0">
          {loading ? (
            <div className="py-16 flex flex-col items-center">
              <div className="text-3xl text-white">Searching for flights</div>
            </div>
          ) : (
            <>
              {flightError ? (
                <>
                  <div className="py-16 flex flex-col items-center">
                    <div className="text-3xl text-white">
                      sorry, no flights for this route
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {airRoutes.map((airport) => (
                    <>
                      <div className="flex items-center justify-between w-full mb-6 border border-white/20 rounded-md py-5 px-5 max-md:flex-wrap">
                        <div className="w-1/4 max-md:w-full">
                          <div className="flex items-center ">
                            {/* <img
                    src="https://fastui.cltpstatic.com/image/upload/resources/images/logos/air-logos/svg_logos/QP.svg"
                    alt=""
                    className="w-11 h-11 object-cover object-center"
                  /> */}
                            <div className="ml-3">
                              <div className="text-xl font-bold text-white">
                                Vistara
                              </div>
                              <span className="text-xs text-white/70">
                                UK-
                                {
                                  airport?.itineraries[0]?.segments[0]?.aircraft
                                    ?.code
                                }
                              </span>
                            </div>
                          </div>
                          <div className="text-[#fc6c3a] mt-4 text-sm">
                            Flight detail
                          </div>
                        </div>
                        <div className="text-white flex items-center justify-between w-1/2 max-md:w-full">
                          <div className="w-1/4 flex items-end justify-start">
                            {
                              airport?.itineraries[0]?.segments[0].departure.at.split(
                                "T"
                              )[1]
                            }
                          </div>
                          <div className="w-2/4 flex flex-col items-center text-white/60">
                            <div>
                              {airport?.itineraries[0]?.duration.slice(2)}
                            </div>
                            <div className="my-2 w-full h-0.5 bg-white/60"></div>
                            <div>
                              {airport?.itineraries[0]?.segments[0]
                                ?.numberOfStops
                                ? airport?.itineraries[0]?.segments[0]
                                    ?.numberOfStops
                                : "no stop"}
                            </div>
                          </div>
                          <div className="w-1/4 flex items-end justify-end">
                            {" "}
                            {
                              airport?.itineraries[0]?.segments[0].arrival.at.split(
                                "T"
                              )[1]
                            }
                          </div>
                        </div>
                        <div className="text-white/70 w-1/4 flex flex-col items-center justify-center max-md:w-full">
                          <span className="text-white/70">Price</span>
                          <div>
                            <span className="text-[#fc6c3a] text-2xl">
                              {airport.price.total}
                            </span>
                          </div>
                          <Link
                            to={`/in/flight/itinerary/${window.location.search}`}
                          >
                            <div className="bg-[#fc6c3a] w-28 h-12 max-md:w-full max-md:mt-4 flex items-center justify-center rounded-md text-black font-bold">
                              Book
                            </div>
                          </Link>
                        </div>
                      </div>
                    </>
                  ))}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Flight;
