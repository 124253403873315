import React, { useEffect, useState } from "react";
import Header from "../../Component/common/Header";
import { FaArrowRight } from "react-icons/fa";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { country } from "../../Container/Data/Country";
import validator from "validator";
import { months } from "../../Container/Data/General";
import axios from "axios";
import { MdError } from "react-icons/md";

function Itinerary() {
  const [showContact, setShowContact] = useState(false);
  const [showAddition, setShowAddition] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [countryName, setCountryName] = useState("");
  const [address, setAddress] = useState("");
  const [address1, setAddress1] = useState("");
  const [loading, setLoading] = useState(false);
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [cardName, setCardName] = useState("");
  const [cardType, setCardType] = useState("");
  const [cardYear, setCardYear] = useState("");
  const [cardMonth, setCardMonth] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardCvv, setCardCvv] = useState("");
  const [error, setError] = useState({
    error: false,
    errormessage: "",
  });

  const Validation = (e, errormessage) => {
    if (e) {
      return true;
    }

    setError({
      error: true,
      errormessage: errormessage,
    });

    setTimeout(() => {
      setError({
        error: false,
        errormessage: errormessage,
      });
    }, 2500);

    return false;
  };

  const getYears = () => {
    let year = [];

    let currentYear = new Date().getFullYear();
    for (let i = currentYear; i < currentYear + 20; i++) {
      year.push(i);
    }
    return year;
  };
  const _handleSubmit = async () => {
    const validphone = validator.isMobilePhone(phone);
    let checkBox = document.getElementById("privacyCheck");

    if (
      !Validation(name, "required valid name") ||
      !Validation(validator.isEmail(email), "required valid email address") ||
      !Validation(validphone, "please provide valid phone number") ||
      !Validation(cardType, "please choose card type") ||
      !Validation(cardNumber, "please provide valid card number") ||
      !Validation(cardName, "please provide valid card name") ||
      !Validation(cardMonth, "please choose card expire month") ||
      !Validation(cardYear, "please choose card expire year") ||
      !Validation(cardCvv, "Please provide valid card cvv") ||
      !Validation(countryName, "please provide valid country name") ||
      !Validation(address, "please provide valid address") ||
      !Validation(city, "please provide valid city name") ||
      !Validation(state, "please provide valid state name") ||
      !Validation(
        checkBox.checked,
        "please provide accept our terms and conditions"
      )
    ) {
      return;
    }

    const formData = {
      name,
      email,
      phone,
      cardType,
      cardNumber,
      cardName,
      cardMonth,
      cardYear,
      countryName,
      address,
      cardCVV: cardCvv,
      address1,
      city,
      state,
    };
    try {
      setLoading(true);
      const request = await axios.post(
        "https://api.wayztrip.com/api/v1/submit",
        formData
      );
      return window.location.assign("/in/thankyou");
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    setShowContact(() => {
      return true;
    });
  }, []);
  return (
    <div className="bg-black max-[1250px]:px-10 py-4 relative min-h-screen max-[950px]:h-auto w-full max-md:h-auto  max-md:pb-0 max-md:-mb-10 max-sm:px-0">
      <div className="px-16 max-md:px-4">
        <Header />
      </div>
      {error.error && (
        <div className="w-73 py-4 px-8 z-50 text-white/70 capitalize fixed top-5 right-4 bg-[#0e0d0d] border border-white/60 rounded-xl flex items-center">
          <MdError color="#ffa0a0" className="mr-3" />
          {error.errormessage}
        </div>
      )}
      <div className="w-full flex items-start px-20 max-lg:px-6  flex-wrap max-lg:flex-col-reverse">
        <div className="w-3/5 mr-16 max-[870px]:w-full max-[870px]:mr-0">
          <div className="mt-12 mb-5">
            <div className="text-2xl text-white">Review itinerary</div>
          </div>
          <div className="my-4 w-full">
            <div
              onClick={() => {
                setShowContact((prevState) => !prevState);
                if (showContact) {
                  return setShowAddition(true);
                }

                return setShowAddition(false);
              }}
              className="flex items-center justify-between bg-[#0e0d0d] text-white rounded-lg py-4 px-8"
            >
              <div className="text-lg">Contact Info</div>
              <div>
                {showContact ? (
                  <IoMdArrowDropup color="white" fontSize={30} />
                ) : (
                  <IoMdArrowDropdown color="white" fontSize={30} />
                )}
              </div>
            </div>
            {showContact && (
              <>
                <div className="mx-3 w-full my-8">
                  <div className="mb-7">
                    <h1 className="text-white">Name</h1>
                    <div className="w-full h-12 my-2">
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        placeholder="Please enter your full name"
                        className="w-full h-full bg-transparent border border-white/40 rounded-md px-5 text-white"
                      />
                    </div>
                  </div>
                  <div className="mb-7">
                    <h1 className="text-white">Email Address</h1>
                    <div className="w-full h-12 my-2">
                      <input
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        type="text"
                        placeholder="Please enter your email address"
                        className="w-full h-full bg-transparent border border-white/40 rounded-md px-5 text-white"
                      />
                    </div>
                  </div>
                  <div className="mb-7">
                    <h1 className="text-white">Phone Number</h1>
                    <div className="w-full h-12 my-2">
                      <input
                        onChange={(e) => {
                          setPhone(e.target.value);
                        }}
                        type="text"
                        placeholder="1234 24123 345"
                        className="w-full h-full bg-transparent border border-white/40 rounded-md px-5 text-white"
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="my-4 w-full">
            <div
              onClick={() => {
                setShowAddition((prevState) => !prevState);
                if (showAddition) {
                  return setShowContact(true);
                }
                return setShowContact(false);
              }}
              className="flex items-center justify-between bg-[#0e0d0d] text-white rounded-lg py-4 px-8"
            >
              <div className="text-lg">Payment information</div>
              <div>
                {showAddition ? (
                  <IoMdArrowDropup color="white" fontSize={30} />
                ) : (
                  <IoMdArrowDropdown color="white" fontSize={30} />
                )}
              </div>
            </div>
            {showAddition && (
              <>
                <div className="mx-3 w-full my-8">
                  <div className="flex items-start">
                    <div className="mb-7 mr-4 w-full">
                      <h1 className="text-white">Card Type</h1>
                      <div className="w-full h-12 my-2">
                        <select
                          onChange={(e) => {
                            setCardType(e.target.value);
                          }}
                          className="w-full h-full bg-transparent border border-white/40 rounded-md px-5 text-white"
                        >
                          <option className="text-black" value="">
                            Card Type
                          </option>
                          {[
                            "Visa",
                            "Master Card",
                            "American Express",
                            "Dinner's Club",
                            "Discover",
                          ].map((card, index) => (
                            <option className="text-black" key={index}>
                              {card}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="mb-7 ml-4 w-full">
                      <h1 className="text-white">Card Number</h1>
                      <div className="w-full h-12 my-2">
                        <input
                          type="text"
                          value={cardNumber}
                          onChange={(e) => {
                            setCardNumber(e.target.value);
                          }}
                          placeholder="Card Number"
                          className="w-full h-full bg-transparent border border-white/40 rounded-md px-5 text-white"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex items-start w-full">
                    <div className="mb-7 mr-4 w-full">
                      <h1 className="text-white">Card Holder name</h1>
                      <div className="w-full h-12 my-2">
                        <input
                          value={cardName}
                          onChange={(e) => {
                            setCardName(e.target.value);
                          }}
                          type="text"
                          placeholder="Card Holding name"
                          className="w-full h-full bg-transparent border border-white/40 rounded-md px-5 text-white"
                        />
                      </div>
                    </div>
                    <div className="mb-7 ml-4 w-full flex items-start">
                      <div className="mb-7 mr-4 w-1/3">
                        <h1 className="text-white">Expire month</h1>
                        <div className="w-full h-12 my-2">
                          <select
                            onChange={(e) => {
                              setCardMonth(e.target.value);
                            }}
                            className="w-full h-full bg-transparent border border-white/40 rounded-md px-1 text-white"
                          >
                            <option className="text-black" value="">
                              select month
                            </option>
                            {months.map((month, index) => (
                              <option className="text-black" key={index}>
                                {month}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="mb-7 mr-2 w-1/3">
                        <h1 className="text-white">Year Expire</h1>
                        <div className="w-full h-12 my-2">
                          <select
                            onChange={(e) => {
                              setCardYear(e.target.value);
                            }}
                            className="w-full h-full bg-transparent border border-white/40 rounded-md  px-1 text-white"
                          >
                            <option className="text-black" value="">
                              year
                            </option>
                            {getYears().map((year, index) => (
                              <option className="text-black" key={index}>
                                {year}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="mb-7 mr-4 w-1/3">
                        <h1 className="text-white">CVC</h1>
                        <div className="w-full h-12 my-2">
                          <input
                            value={cardCvv}
                            onChange={(e) => {
                              setCardCvv(e.target.value);
                            }}
                            type="text"
                            placeholder="123"
                            className="w-full h-full bg-transparent border border-white/40 rounded-md px-2 text-white"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="my-4 w-full">
            <div
              onClick={() => {
                setShowAddition((prevState) => !prevState);
                if (showAddition) {
                  return setShowContact(true);
                }
                return setShowContact(false);
              }}
              className="flex items-center justify-between bg-[#0e0d0d] text-white rounded-lg py-4 px-8"
            >
              <div className="text-lg">Addition information</div>
              <div>
                {showAddition ? (
                  <IoMdArrowDropup color="white" fontSize={30} />
                ) : (
                  <IoMdArrowDropdown color="white" fontSize={30} />
                )}
              </div>
            </div>
            {showAddition && (
              <>
                <div className="mx-3 w-full my-8">
                  <div className="flex items-start">
                    <div className="mb-7 mr-4 w-full">
                      <h1 className="text-white">Country*</h1>
                      <div className="w-full h-12 my-2">
                        <select
                          onChange={(e) => {
                            setCountryName(e.target.value);
                          }}
                          className="w-full h-full bg-transparent border border-white/40 rounded-md px-5 text-white"
                        >
                          <option className="text-black" value="">
                            select Country
                          </option>
                          {country.map((country, index) => (
                            <option className="text-black" key={index}>
                              {country.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="mb-7 ml-4 w-full">
                      <h1 className="text-white">Address 1*</h1>
                      <div className="w-full h-12 my-2">
                        <input
                          onChange={(e) => {
                            setAddress(e.target.value);
                          }}
                          value={address}
                          type="text"
                          placeholder="Address"
                          className="w-full h-full bg-transparent border border-white/40 rounded-md px-5 text-white"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex items-start w-full">
                    <div className="mb-7 mr-4 w-full">
                      <h1 className="text-white">Address 2*</h1>
                      <div className="w-full h-12 my-2">
                        <input
                          value={address1}
                          type="text"
                          onChange={(e) => {
                            setAddress1(e.target.value);
                          }}
                          placeholder="Address line 2"
                          className="w-full h-full bg-transparent border border-white/40 rounded-md px-5 text-white"
                        />
                      </div>
                    </div>
                    <div className="mb-7 ml-4 w-full">
                      <h1 className="text-white">City*</h1>
                      <div className="w-full h-12 my-2">
                        <input
                          type="text"
                          value={city}
                          onChange={(e) => {
                            setCity(e.target.value);
                          }}
                          placeholder="City(ex. Boston)"
                          className="w-full h-full bg-transparent border border-white/40 rounded-md px-5 text-white"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex items-start w-full">
                    <div className="mb-7 mr-4 w-full">
                      <h1 className="text-white">State Code*</h1>
                      <div className="w-full h-12 my-2">
                        <input
                          type="text"
                          value={state}
                          onChange={(e) => {
                            setState(e.target.value);
                          }}
                          placeholder="State"
                          className="w-full h-full bg-transparent border border-white/40 rounded-md px-5 text-white"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="flex items-center justify-center my-2">
            <input type="checkbox" className="w-5 h-5" id="privacyCheck" />
            <p className="text-xs text-white/40 ml-3">
              By clicking BOOK NOW, I agree that I have read and accepted the
              above policies and wayztrip.com's USA Terms & Conditions and
              Privacy Policy.
            </p>
          </div>
          {loading ? (
            <div className="bg-[#fc6c3a] py-4 text-center px-6  font-bold rounded-md">
             Loading
            </div>
          ) : (
            <div
              className="bg-[#fc6c3a] py-4 text-center px-6  font-bold rounded-md"
              onClick={_handleSubmit}
            >
              Confirm Order
            </div>
          )}
        </div>
        <div className="w-1/3 sticky top-0 max-[870px]:relative max-[870px]:w-full">
          <div className="mt-12">
            <div className="text-white/70 flex items-center">
              Jorhat{" "}
              <span className="mx-4">
                <FaArrowRight color="#fc6c3a" />{" "}
              </span>
              Bangalore Sun, 8 Sep 2024
            </div>
          </div>
          <div className="my-10 relative px-6">
            <div className="h-full w-0.5 -left-0  absolute border-l border-l-white/60 border-dashed flex items-center flex-col justify-between">
              <div className="w-3 h-3 bg-white rounded-full "></div>
              <div className="w-3 h-3 bg-white rounded-full "></div>
            </div>
            <div className="flex items-center">
              <div>
                <div>
                  <img
                    src="https://fastui.cltpstatic.com/image/upload/resources/images/logos/air-logos/svg_logos/6E.svg"
                    alt=""
                    className="w-12 h-12"
                  />
                </div>
                <div className="text-white/70 text-xs">
                  <h2>IndiGo</h2> <span>6E-6153</span> <h5>Economy</h5>
                </div>
              </div>
              <div className="text-2xl text-white ml-7 font-bold">
                14:40 JRH{" "}
                <span className="text-xs text-white/70 font-thin">
                  Rowriah, Terminal , Jorhat
                </span>
              </div>
            </div>
            <div className="flex items-center mt-12 ">
              <div>
                <div>
                  <img
                    src="https://fastui.cltpstatic.com/image/upload/resources/images/logos/air-logos/svg_logos/6E.svg"
                    alt=""
                    className="w-12 h-12"
                  />
                </div>
                <div className="text-white/70 text-xs">
                  <h2>IndiGo</h2> <span>6E-6153</span> <h5>Economy</h5>
                </div>
              </div>
              <div className="text-2xl text-white ml-7 font-bold">
                14:40 JRH{" "}
                <span className="text-xs text-white/70 font-thin">
                  Rowriah, Terminal , Jorhat
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Itinerary;
