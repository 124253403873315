import React from "react";
import Header from "../../Component/common/Header";
import Footer from "../../Component/common/Footer";

function About() {
  return (
    <>
      <div className="bg-black px-24 py-4 relative max-md:px-10 h-auto w-full max-md:h-auto  max-md:pb-0 max-md:-mb-10 max-sm:px-4">
        <Header />
        <div className="py-12">
          <div className="text-5xl font-bold mb-7 text-white">
            Let wayztrip be your go-to for affordable air travel. Book your next
            adventure with us today!
          </div>
          <div>
            <img
              src="https://images.pexels.com/photos/47044/aircraft-landing-reach-injection-47044.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
              alt=""
              className="w-full h-[300px] rounded-lg my-10 object-cover object-top"
            />
          </div>
          <div className="text-5xl text-white/80">About wayztrip</div>
          <div className="text-lg text-white/70">
            <p className="mt-4">
              wayztrip is your ultimate destination for budget-friendly travel.
              We're a dedicated platform committed to finding you the cheapest
              flights without compromising on quality or service. Unlike other
              travel sites that might prioritize certain airlines, we scour the
              market to bring you a comprehensive range of options, ensuring you
              always get the best deal.
            </p>
            <p className="mt-4">
              Our mission is simple: to make air travel affordable for everyone.
              Whether you're a seasoned traveler or a first-time flyer, wayztrip
              is your trusted partner in finding the perfect flight. We believe
              that everyone deserves to experience the joy of travel, and we're
              here to make it happen.
            </p>
            <p className="mt-4">
              So, buckle up and get ready to explore the world without breaking
              the bank. Let wayztrip be your wingman to incredible adventures.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default About;
