import React from "react";
import Header from "../../Component/common/Header";

function Thank() {
  return (
    <div className="bg-black max-[1250px]:px-10 py-4 relative min-h-screen max-[950px]:h-auto w-full max-md:h-auto  max-md:pb-0 max-md:-mb-10 max-sm:px-0">
      <div className="px-16 max-md:px-2">
        <Header />
      </div>
      <div className=" flex flex-col items-center py-20">
        <div className="text-white text-6xl font-bold">Thank You</div>
        <div className="mt-3 w-1/2 max-md:w-full max-md:px-5">
          <span className="text-white/80 text-center">
            Thank you for reaching out to us! We’ve received your query and are
            here to assist you with your request.{" "}
          </span>
        </div>
      </div>
    
    </div>
  );
}

export default Thank;
